import { Plugin } from '@nuxt/types'
import { extend, configure } from 'vee-validate'
import { required, numeric, email } from 'vee-validate/dist/rules'
import VueI18n from 'vue-i18n'

const CUPS_REGEX = /^[A-Z]{2}\d{16}[A-Z]{2}\d{0,1}[A-Z]{0,1}$/
const CHECKSUM_TABLE = 'TRWAGMYFPDXBNJZSQVHLCKE'.split('')

const veeValidate: Plugin = ({ app }) => {
  configureVeeValidate(app.i18n as VueI18n)
}

const fmod = (a: number, b: number) => Number(a - Math.floor(a / b) * b)

const checkValidChecksum = (cups: string) => {
  const digits = cups.substr(2, 16)
  const expectedChecksum = cups.substr(18, 2)

  const coefficient = fmod(parseInt(digits), 529)

  const firstNumber = Math.floor(coefficient / 23)
  const secondNumber = coefficient % 23

  const firstDigit = CHECKSUM_TABLE[firstNumber]
  const secondDigit = CHECKSUM_TABLE[secondNumber]

  return expectedChecksum === firstDigit + secondDigit
}

export default veeValidate

export function configureVeeValidate(i18n: VueI18n) {
  configure({
    defaultMessage: (_, values) =>
      i18n.t(`fieldErrors.${values._rule_}`, values) as string,
  })

  extend('required', required)
  extend('numeric', numeric)
  extend('email', email)
  extend('phone', {
    validate: () => {
      return true
    },
  })
  extend('trueRequired', {
    ...required,
    validate: (value) => value,
  })
  extend('isValidPostalCode', {
    validate: (municipality) => {
      const { id } = municipality
      return id !== undefined
    },
  })
  extend('isRequiredPostalCode', {
    validate: (municipality) => {
      const { id } = municipality
      return id !== ''
    },
  })
  extend('isValidAddress', {
    validate: (selectedStreet) => {
      const { cups } = selectedStreet
      return cups !== undefined
    },
  })
  extend('isValidCups', {
    validate: (cups: string) => {
      return cups && cups.match(CUPS_REGEX) ? checkValidChecksum(cups) : false
    },
  })
  extend('isEmptyCups', {
    ...required,
  })
}
