import { externalTarificatorService } from '@services/externalTarificatorService'
import { ISupplyPointRepository } from '../domain/supplyPoint.repository'
import { Coordinates } from '../domain/supplyPoint.types'
import {
  parseSupplyPointToDomain,
  CoordinatesDTO,
} from '../infrastructure/supplyPoint.parser'

export const supplyPointService: ISupplyPointRepository = {
  getSupplyPoint: (cups: string) => {
    return externalTarificatorService.get<CoordinatesDTO, Coordinates>(
      {
        url: `/get-url?url=supply-point&cups=${cups}`,
      },
      {
        toDomain: (coordinatesDTO) => parseSupplyPointToDomain(coordinatesDTO),
      }
    )
  },
}
