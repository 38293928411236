import { formStoreState } from './types'
import { blankSolarOffer } from '@modules/solarOffer/domain/solarOffer.types'

export const initialState: formStoreState = {
  partner: '',
  hasPropertyConditions: false,
  contact: {
    firstname: '',
    email: '',
    isEmailValid: true,
    phone: '',
    comments: '',
    customerConsumption: '',
    buyMoment: '',
  },
  permissions: {
    acceptPermissions: '',
  },
  nextStep: '',
  resetValidation: () => ({}),
  submitSuccess: false,
  hasServerError: false,
  hasFormError: false,
  hasInvalidEmail: false,
  hasInvalidPhone: false,
  requiredAddress: true,
  municipality: {
    postalCode: '',
    id: '',
    province: '',
    city: '',
    ine: '',
  },
  cups: '',
  supplyPoint: {
    cups: '',
    address: {
      street: '',
      streetType: 'CL',
      number: '',
      doorway: '',
      stair: '',
      floor: '',
      door: '',
    },
  },
  coordinates: {
    lat: 0,
    lng: 0,
  },
  googleAddress: {
    roadNumber: '',
    roadName: '',
    locality: '',
    province: '',
    postalCode: '',
    autonomousCommunity: '',
    formatedAddress: '',
  },
  supplyPointResults: [],
  solarOffer: blankSolarOffer(),
  hasNoLocationFound: false,
  notFoundMyAddressSelected: false,
  visitUrl: '',
  scheduleError: false,
  commercialAgent: '',
  isVisit: true,
  hasAddressError: false,
  hasNumberError: false,
  solarModuleErrors: {
    hasErrorSearchingAddress: false,
    hasErrorFindingRoof: false,
    hasErrorInSimulation: false,
    hasEmptySupplyPoints: false,
  },
  lastInteraction: Date.now(),
}
