import { Partners, ConfigObject } from './types'
import {
  defaultconfig,
  bysidecar,
  bysidecarinbound,
  konecta,
  andorranasac,
  viatek,
  premiumhouses,
  lucasfox,
  coldknocker,
  grupoinmark,
  simonemmanuel,
  convertia,
  correos,
  afiliados,
  hotknocking,
  referidos,
} from './partnerConfig'
export const configDic: Record<Partners, ConfigObject> = {
  holaluz: defaultconfig,
  bysidecar,
  bysidecarinbound,
  konecta,
  andorranasac,
  viatek,
  premiumhouses,
  lucasfox,
  coldknocker,
  grupoinmark,
  simonemmanuel,
  convertia,
  correos,
  afiliados,
  hotknocking,
  referidos,
}
