export type SolarOffer = {
  id: string
  panels: number
  subsidy: number
  installationValue: number
  scSaving: number
  scSolar: number
  solar: number
  solarBilling: number
  surplusBilling: number
  surplusSaving: number
  grossCustomerSavingsWithTaxes: number
  costWithSubsidy: number
}

export type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never
}[keyof T]

export const blankSolarOffer = () => ({
  id: '',
  panels: 0,
  subsidy: 0,
  installationValue: 0,
  scSaving: 0,
  scSolar: 0,
  solar: 0,
  solarBilling: 0,
  surplusBilling: 0,
  surplusSaving: 0,
  grossCustomerSavingsWithTaxes: 0,
  costWithSubsidy: 0,
})

export type SolarUrl = {
  urlBooking: string
  isVisit: boolean
}
