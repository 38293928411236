import { Partners } from './config/types'
import { configDic } from './config'

const partner = (process.env.PARTNER as Partners) || 'holaluz'
const partnerConfig = configDic[partner]
  ? { ...configDic.holaluz, ...configDic[partner] }
  : configDic.holaluz

const modes = {
  isStagingEnv: process.env.ENV === 'staging',
  isDevelopmentEnv: process.env.ENV === 'development',
  isProductionEnv: process.env.ENV === 'production',
  isQaEnv: process.env.ENV === 'qa',
}

const configs = {
  production: {
    externalTarificatorBackendUrl: 'https://et-backend.holaluz.com',
    videoCallURL:
      'https://holaluz.zohobookings.com/#/customer/4181443000001240264',
    googleGeocodingApikey: 'AIzaSyBrd9A9PihHomnHxb6Mypu_BbRuN-Zg0Ew',
    partner,
    partnerConfig,
    hasDatadog: true,
    datadogEnv: 'prod',
  },
  qa: {
    externalTarificatorBackendUrl: 'https://et-backend.staging.holaluz.com',
    videoCallURL:
      'https://holaluz.zohobookings.com/#/customer/4181443000001240264',
    googleGeocodingApikey: 'AIzaSyBrd9A9PihHomnHxb6Mypu_BbRuN-Zg0Ew',
    partner,
    partnerConfig,
    hasDatadog: false,
    datadogEnv: 'staging',
  },
  staging: {
    externalTarificatorBackendUrl: 'https://et-backend.staging.holaluz.com',
    videoCallURL:
      'https://holaluz.zohobookings.com/#/customer/4181443000001240264',
    googleGeocodingApikey: 'AIzaSyBrd9A9PihHomnHxb6Mypu_BbRuN-Zg0Ew',
    partner,
    partnerConfig,
    hasDatadog: false,
    datadogEnv: 'staging',
  },
  development: {
    externalTarificatorBackendUrl: 'https://et-backend.staging.holaluz.com',
    videoCallURL:
      'https://holaluz.zohobookings.com/#/customer/4181443000001240264',
    googleGeocodingApikey: 'AIzaSyBrd9A9PihHomnHxb6Mypu_BbRuN-Zg0Ew',
    partner,
    partnerConfig,
    hasDatadog: false,
    datadogEnv: 'staging',
  },
}

type Env = 'production' | 'qa' | 'staging' | 'development'

const env = (process.env.ENV as Env) || 'development'

export default { ...configs[env], ...modes }
