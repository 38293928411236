import { ConfigObject } from '../types'
import { NextStep } from '../../modules/lead/domain'
const config: ConfigObject = {
  logo: {
    fileName: 'partners-brand.png',
    width: 154,
    height: 70,
    label: 'Logo partner',
    link: 'https://holaluz.com/',
    alt: 'Partner',
  },
  css: 'default.css',
  nextSteps: {
    show: true,
    options: [
      NextStep.Call,
      NextStep.CallOffer,
      NextStep.OfferVisit,
      NextStep.ScheduleVisit,
    ],
  },
  activeSections: [
    'agentId',
    'propertyInfo',
    'contactInfo',
    'customerConsumption',
    'solarModuleMap',
    'contactPermissions',
  ],
  privacityUrl: 'https://www.holaluz.com/documentos-legales/aviso-legal/',
  isColdKnocker: false,
}

export default config
