import { NextStep } from '../../modules/lead/domain'
import { ConfigObject } from '../types'
const config: ConfigObject = {
  logo: {
    fileName: 'konecta-brand.png',
    width: 232,
    height: 70,
    label: 'Konecta',
    link: 'https://www.grupokonecta.com/',
    alt: 'Konecta',
  },
  css: 'default.css',
  nextSteps: {
    show: false,
    withOffer: NextStep.OfferVisit,
    withoutOffer: NextStep.Call,
  },
  privacityUrl: 'https://www.grupokonecta.com/politica-privacidad',
}

export default config
