import { NextStep } from '../../modules/lead/domain'
import { ConfigObject } from '../types'
const config: ConfigObject = {
  logo: {
    fileName: 'coldknockers-logo.png',
    width: 290,
    height: 70,
    label: 'Simulador Solar',
    link: 'https://www.holaluz.com/',
    alt: 'Simulador Solar',
  },
  css: 'default.css',
  nextSteps: {
    show: true,
    options: [NextStep.ScheduleVisit, NextStep.Call],
    defaultOption: NextStep.ScheduleVisit,
  },
  privacityUrl: 'https://www.holaluz.com/documentos-legales/aviso-legal/',
  isColdKnocker: true,
  activeSections: [
    'agentId',
    'contactInfo',
    'solarModuleMap',
    'contactPermissions',
  ],
}

export default config
