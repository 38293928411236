import { NextStep } from '../../modules/lead/domain'
import { ConfigObject } from '../types'
const config: ConfigObject = {
  logo: {
    fileName: 'andorrana-sac-brand.png',
    width: 284,
    height: 70,
    label: 'Andorrana SAC',
    alt: 'Andorrana SAC',
  },
  css: 'default.css',
  nextSteps: {
    show: false,
    withOffer: NextStep.OfferVisit,
    withoutOffer: NextStep.Call,
  },
  privacityUrl: 'https://www.holaluz.com/documentos-legales/aviso-legal/',
}

export default config
