import { ISupplyPointRepository } from '@modules/supplyPoint/domain/supplyPoint.repository'
import { Coordinates } from '@modules/supplyPoint/domain/supplyPoint.types'
type Parameters = { cups: string }

type Services = {
  supplyPointService: ISupplyPointRepository
}

type Callbacks = {
  // fix any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess: (data: Coordinates) => void
  onCupsNotFound: () => void
  onError: () => void
}

function handleGetSupplyPoint({
  supplyPointService,
}: Services): AsyncUseCase<Parameters, Callbacks> {
  return { execute }

  async function execute(
    { cups }: Parameters,
    { onSuccess, onError, onCupsNotFound }: Callbacks
  ) {
    const coordinates = await supplyPointService.getSupplyPoint(cups)

    if (coordinates.isErr()) {
      if (coordinates.error.status === 404) {
        onCupsNotFound()
      }
      return onError()
    }

    onSuccess(coordinates.value)
  }
}

export { handleGetSupplyPoint }
