import { SolarOffer, SolarUrl } from '../domain/solarOffer.types'

export type SolarFee = {
  scSaving: number
  scSolar: number
  solar: number
  solarBilling: number
  surplusBilling: number
  surplusSaving: number
}

export type SolarFeeDTO = {
  scSaving: string
  scSolar: string
  solar: string
  solarBilling: string
  surplusBilling: string
  surplusSaving: string
}

export type SolarOfferDTO = {
  type: string
  id: string
  panels: number
  capex: number
  subsidy: number
  allowedSubsidy: boolean
  installationValue: number
  initialInvestment: number
  selfConsumptionFee: number
  surplusFee: number
  grossCustomerSavingsWithTaxes: number
  monthlyGrossCustomerSavingsWithTaxes: number
  futureGrossCustomerSavingsWithTaxes: number | null
  newInvoice: number
  savingsFormat: string
  production: number
  offerResizeInfo: number | null
  monthlySurplusSavings: number
  yearlySurplusSavings: number
  fee: SolarFeeDTO
  payback: {
    years: number
    months: number
  }
  paybackWithSubsidy: {
    years: number
    months: number
  }
  savingsInYears: {
    10: number
    25: number
  }
}

export const parseSolarOfferToDomain = (
  solarOfferDTO: SolarOfferDTO
): SolarOffer => {
  const fee = parserFee(solarOfferDTO.fee)
  const solarOffer = {
    ...fee,
    id: solarOfferDTO.id,
    panels: solarOfferDTO.panels,
    subsidy: solarOfferDTO.subsidy,
    installationValue: solarOfferDTO.installationValue,
    grossCustomerSavingsWithTaxes: solarOfferDTO.grossCustomerSavingsWithTaxes,
    costWithSubsidy: solarOfferDTO.installationValue - solarOfferDTO.subsidy,
  }
  return solarOffer
}

function parserFee(solarFeeDTO: SolarFeeDTO): SolarFee {
  return {
    scSaving: Number(solarFeeDTO.scSaving),
    scSolar: Number(solarFeeDTO.scSolar),
    solar: Number(solarFeeDTO.solar),
    solarBilling: Number(solarFeeDTO.solarBilling),
    surplusBilling: Number(solarFeeDTO.surplusBilling),
    surplusSaving: Number(solarFeeDTO.surplusSaving),
  }
}

export type SolarUrlDTO = {
  url_booking: string
  is_visit: boolean
}

export const parseSolarUrlToDomain = (solarUrlDTO: SolarUrlDTO): SolarUrl => ({
  urlBooking: solarUrlDTO.url_booking,
  isVisit: solarUrlDTO.is_visit,
})
