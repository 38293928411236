import { Lead } from '@modules/lead/domain/lead.types'
import { NextStep, Permissions } from '../domain'

export type LeadDTO = {
  contact: {
    firstname: string
    phone: string
    email: string
    address: string
    zip: string
    province: string
    city: string
    comentarios: string
    permissions: Permissions
    agentecomercial: string
    lead_reference_number: string
    importeultimafactura: string
    momento_compra: string
    custom_referrer_url: string
    source: string
    coordenadas: string
  }
  nextStep: NextStep | ''
  offer: {
    cups: string
    address: string
    offerId: string
    coordinates: object
  }
}

export function parseFromDomain({
  contact: {
    firstname,
    phone,
    email,
    address: contactAddress,
    municipality,
    comments,
    permissions,
    commercialAgent,
    customerConsumption,
    buyMoment,
    customReferrerUrl,
    source,
    coordenadas: coordinates,
  },
  offer: { cups, address, offerId, coordinates: coordinatesObj },
  nextStep,
}: Lead): LeadDTO {
  return {
    contact: {
      firstname,
      phone,
      email,
      address:
        `${contactAddress.streetType} ${contactAddress.street} ${contactAddress.number} ${contactAddress.doorway} ${contactAddress.stair} ${contactAddress.floor} ${contactAddress.door}`
          .replace(/\s+/g, ' ')
          .trim(),
      zip: municipality.postalCode,
      province: municipality.province,
      city: municipality.city,
      comentarios: comments,
      permissions,
      agentecomercial: commercialAgent,
      lead_reference_number: commercialAgent,
      importeultimafactura: customerConsumption,
      momento_compra: buyMoment,
      custom_referrer_url: customReferrerUrl,
      source,
      coordenadas:
        coordinates.lat !== 0 ? `${coordinates.lat}, ${coordinates.lng}` : '',
    },
    nextStep,
    offer: {
      cups,
      address,
      offerId,
      coordinates: coordinatesObj,
    },
  }
}
