import { Coordinates } from '../domain/supplyPoint.types'

export type CoordinatesDTO = {
  latitude: number
  longitude: number
}

export const parseSupplyPointToDomain = ({
  latitude,
  longitude,
}: CoordinatesDTO): Coordinates => ({
  lat: latitude || 0,
  lng: longitude || 0,
})
