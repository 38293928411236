import { externalTarificatorService } from '@services/externalTarificatorService'
import {
  ISolarOfferRepository,
  ISolarUrlRepository,
} from '../domain/solarOffer.repository'
import { SolarOffer, SolarUrl } from '../domain/solarOffer.types'
import { Coordinates } from '@modules/supplyPoint/domain/supplyPoint.types'
import {
  parseSolarOfferToDomain,
  SolarOfferDTO,
  parseSolarUrlToDomain,
  SolarUrlDTO,
} from './solarOffer.parser'

export const solarOfferService: ISolarOfferRepository = {
  getSolarOffer: (cups: string, coordinates: Coordinates, source = 'WEB') => {
    return externalTarificatorService.get<SolarOfferDTO, SolarOffer>(
      {
        url: `/get-offer?cups=${cups}&latitude=${coordinates.lat}&longitude=${coordinates.lng}&source=${source}`,
      },
      {
        toDomain: (coordinatesDTO) => parseSolarOfferToDomain(coordinatesDTO),
      }
    )
  },
}

export const solarUrlService: ISolarUrlRepository = {
  getSolarUrl: (postalCode: string) => {
    return externalTarificatorService.get<SolarUrlDTO, SolarUrl>(
      {
        url: `/get-url?url=urlBooking&postalCode=${postalCode}`,
      },
      {
        toDomain: (solarUrl) => parseSolarUrlToDomain(solarUrl),
      }
    )
  },
}
